import React from "react"
import PageLayout from "../components/PageLayout"
import PageHeader from "../components/PageHeader"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PropTypes } from "prop-types"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Badge from "react-bootstrap/Badge"

const CreditItem = ({ credit }) => {
  const comingSoon = credit.dateDifference < 0 ? true : false
  return (
    <Col xs={12}>
      <div className="mb-3 me-2">
        <Row className="gx-3">
          <Col xs={3} md={"auto"}>
            <GatsbyImage
              className="img-fluid rounded-md-start"
              alt={`Poster image for ${credit.title}`}
              image={getImage(credit.coverImage)}
            />
          </Col>
          <Col
            xs={9}
            md={8}
            className="d-flex justify-content-center flex-column"
          >
            <div className="fs-5 justify-content-between d-flex">
              <span className="align-items-center d-flex">
                {credit.title}
                {comingSoon && (
                  <Badge
                    className="ms-2"
                    pill
                    bg="danger"
                    style={{ fontSize: "10px" }}
                  >
                    Coming soon!
                  </Badge>
                )}
              </span>
              <span>{credit.year}</span>
            </div>
            <div className="mb-1">
              {credit.tags.map((t) => (
                <Badge className="me-2" bg="dark" key={`${credit.id}-${t}`}>
                  {t}
                </Badge>
              ))}
            </div>
            <div
              className="text-muted w-75 copy"
              dangerouslySetInnerHTML={{
                __html: credit.body.childMarkdownRemark.html,
              }}
            />
            {credit.musicLink && (
              <p className="copy">
                <Link to={"/music/" + credit.musicLink.slug}>
                  Check out the music
                </Link>
              </p>
            )}
          </Col>
        </Row>
      </div>
    </Col>
  )
}

CreditItem.propTypes = {
  credit: PropTypes.object.isRequired,
}

const Credits = ({ data }) => {
  const credits = data.credits.nodes
  return (
    <PageLayout
      title="Credits"
      contentPath="Credits"
      metaDescription="Selections of Credits by composer Tim Sandberg"
    >
      <PageHeader title="Credits" />
      <Container>
        <Row className="g-3 gx-3" style={{ maxWidth: "800px" }}>
          {credits.map((c) => (
            <CreditItem key={c.id} credit={c} />
          ))}
        </Row>
      </Container>
    </PageLayout>
  )
}

export const query = graphql`
  {
    credits: allContentfulCredit(sort: { fields: releaseDate, order: DESC }) {
      nodes {
        id
        body {
          childMarkdownRemark {
            html
          }
        }
        tags
        musicLink {
          slug
        }
        coverImage {
          gatsbyImageData(height: 100, resizingBehavior: SCALE)
        }
        title
        year: releaseDate(formatString: "YYYY")
        dateDifference: releaseDate(difference: "seconds")
      }
    }
  }
`

Credits.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Credits
